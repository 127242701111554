/* eslint-disable max-lines-per-function */
import React, {useCallback, useEffect, useState} from 'react';
import I18n from 'app/i18n';
import {Link, animateScroll} from 'react-scroll';
import {NavLink} from 'react-router-dom';
import {flagImage, isDesktop} from 'app/utils';
import SvgLogoEgbAffiliates from 'images/logos/egb-logo-affiliates.svg';
import FooterPartnerList from 'site_version/common/FooterPartnerList';
import AffiliatesLandingFaq from 'app/components/landing_affiliates/AffiliatesLandingFaq';
import AffiliatesLandingTop from 'app/components/landing_affiliates/AffiliatesLandingTop';
import AffiliatesLandingTopSlider from 'app/components/landing_affiliates/AffiliatesLandingTopSlider';
import AffiliatesLandingProductSlider from 'app/components/landing_affiliates/AffiliatesLandingProductSlider';
import {setLanguageStorage} from 'app/utils/Language';
import {switchLanguage} from 'app/utils/langs/switchLanguage';

const AffiliatesLanding: React.FC = () => {
    const [activeLang, setActiveLang] = useState(localStorage.getItem('language'));

    useEffect(() => {
        setActiveLang(localStorage.getItem('language'));
    }
    , [activeLang]);

    const options = {
        duration: 300,
        smooth: false,
        spy: false
    };

    useEffect(() => {
        animateScroll.scrollToTop(options);
    }
    , []);

    const changeLangHandler = (e: React.MouseEvent<HTMLButtonElement>, lang: string) => {
        setActiveLang(lang);
        setLanguageStorage(lang);
        switchLanguage(e as unknown as Event, lang);
    };

    const changeLangHandlerEn = useCallback((e: React.MouseEvent<HTMLButtonElement>) => changeLangHandler(e, 'en'), [activeLang]);
    const changeLangHandlerRu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => changeLangHandler(e, 'ru'), [activeLang]);

    return <>
        <div className="landing-promo-header affiliates">
            <div className="landing-promo-header-inner">
                <Link
                    className="logo-landing-promo"
                    activeClass="active"
                    to="navAffiliates"
                    smooth={true}
                    offset={-79}
                    duration={400}
                >
                    <img src={SvgLogoEgbAffiliates} alt="EGB.com" title="EGB.COM"/>
                </Link>
                <div className="menu-navigation">
                    <span className="menu-navigation-item">
                        <Link
                            className="link-menu"
                            activeClass="active"
                            to="navProducts"
                            spy={true}
                            smooth={true}
                            isDynamic = {true}
                            offset={-79}
                            duration={400}
                        >{I18n.t('menu_links.products')}</Link>
                    </span>
                    <span className="menu-navigation-item">
                        <Link
                            className="link-menu"
                            activeClass="active"
                            to="navCommissions"
                            spy={true}
                            smooth={true}
                            isDynamic = {true}
                            offset={-79}
                            duration={400}
                        >{I18n.t('menu_links.commissions')}</Link>
                    </span>
                    <span className="menu-navigation-item">
                        <Link
                            className="link-menu"
                            activeClass="active"
                            to="navFaq"
                            spy={true}
                            smooth={true}
                            isDynamic = {true}
                            offset={-79}
                            duration={400}
                        >{I18n.t('menu_links.faq')}</Link>
                    </span>
                    <span className="menu-navigation-item">
                        <Link
                            className="link-menu"
                            activeClass="active"
                            to="navContacts"
                            smooth={true}
                            isDynamic = {true}
                            offset={-79}
                            duration={400}
                        >{I18n.t('menu_links.contacts')}</Link>
                    </span>
                </div>
                <div className="menu-lang">
                    <button className={`menu-lang-item${activeLang === 'ru' ? ' active' : ''}`} onClick={changeLangHandlerRu} title="RU">
                        <span className="icon">{flagImage('ru')}</span>
                    </button>
                    <button className={`menu-lang-item${activeLang === 'en' ? ' active' : ''}`} onClick={changeLangHandlerEn} title="EN">
                        <span className="icon">{flagImage('gb')}</span>
                    </button>
                </div>
                <div className="menu-user">
                    <span className="menu-user-item">
                        <NavLink
                            className="button-login-affiliates"
                            to="https://my.egbaffiliates.com/login"
                            rel="noreferrer noopener"
                        >{I18n.t('user.entrance')}</NavLink>
                    </span>
                    <span className="menu-user-item">
                        <NavLink
                            className="button-registration-affiliates"
                            to="https://my.egbaffiliates.com/registration"
                            rel="noreferrer noopener"
                        >{I18n.t('user.registration')}</NavLink>
                    </span>
                </div>
            </div>
        </div>
        <main className="landing-promo-content affiliates" id="navAffiliates">
            <div className="landing-promo-top">
                <div className="landing-promo-top-inner">
                    <div className="landing-promo-slogan">
                        <h1 dangerouslySetInnerHTML={{__html: I18n.t('affiliate.slogan')}}/>
                    </div>
                    {isDesktop() ? <AffiliatesLandingTop/> : <AffiliatesLandingTopSlider/>}
                </div>
            </div>
            <div className="landing-promo-middle" id="navProducts">
                <div className="landing-promo-middle-inner">
                    <h2>{I18n.t('menu_links.products')}</h2>
                    <div className="landing-promo-middle-left">
                        {isDesktop()
                            ? <div className="landing-promo-product-list">
                                <div className="landing-promo-product-list-item">
                                    <svg className="svg-default product-icon">
                                        <use xlinkHref="#svg-tap_bar_play"/>
                                    </svg>
                                    <span className="title-product">{I18n.t('menu_links.bets')}</span>
                                    <span className="description-product">{I18n.t('affiliate.products_text_1')}</span>
                                </div>
                                <div className="landing-promo-product-list-item">
                                    <svg className="svg-default product-icon">
                                        <use xlinkHref="#svg-tap_bar_play_sport"/>
                                    </svg>
                                    <span className="title-product">{I18n.t('menu_links.sport')}</span>
                                    <span className="description-product">{I18n.t('affiliate.products_text_2')}</span>
                                </div>
                                <div className="landing-promo-product-list-item">
                                    <svg className="svg-default product-icon">
                                        <use xlinkHref="#svg-tap_bar_play_casino"/>
                                    </svg>
                                    <span className="title-product">{I18n.t('menu_links.casino')}</span>
                                    <span className="description-product">{I18n.t('affiliate.products_text_3')}</span>
                                </div>
                            </div>
                            : <AffiliatesLandingProductSlider/>}
                    </div>
                    <div className="landing-promo-middle-right">
                        <div className="landing-promo-devices"/>
                    </div>
                </div>
            </div>
            <div className="landing-promo-middle-second" id="navCommissions">
                <div className="landing-promo-middle-inner">
                    <h2>{I18n.t('menu_links.commissions')}</h2>
                    <div className="landing-promo-commissions">
                        <div className="landing-promo-commission">
                            <span className="section-icon yellow"/>
                            <span className="section-title">Revenue share</span>
                            <div className="section-description">
                                <p>{I18n.t('affiliate.commissions_text_1')}.</p>
                                <p>{I18n.t('affiliate.commissions_text_1_1')}</p>
                            </div>
                        </div>
                        <div className="landing-promo-commission">
                            <span className="section-icon red"/>
                            <span className="section-title">CPA</span>
                            <div className="section-description">
                                <p>{I18n.t('affiliate.commissions_text_2')}.</p>
                                <p>{I18n.t('affiliate.commissions_text_2_1')}</p>
                            </div>
                        </div>
                        <div className="landing-promo-commission">
                            <span className="section-icon blue"/>
                            <span className="section-title">HYBRID</span>
                            <div className="section-description">
                                <p>{I18n.t('affiliate.commissions_text_3')}.</p>
                                <p>{I18n.t('affiliate.commissions_text_3_1')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landing-promo-faq" id="navFaq">
                <div className="landing-promo-middle-inner">
                    <h3>{I18n.t('menu_links.faq')}</h3>
                    <AffiliatesLandingFaq/>
                </div>
            </div>
            <div className="landing-promo-middle-threed" id="navContacts">
                <div className="landing-promo-middle-inner">
                    <div className="landing-promo-contacts">
                        <NavLink to="https://telegram.me/RomanEGBaff" className="landing-promo-contact">
                            <svg className="svg-default contact-icon">
                                <use xlinkHref="#svg-telegram-icon-1"/>
                            </svg>
                            <div>
                                <span className="title-contact">Telegram</span>
                                <span className="adds-contact">@RomanEGBaff</span>
                            </div>
                        </NavLink>
                        <NavLink to="mailto:roman@egb.com" className="landing-promo-contact">
                            <svg className="svg-default contact-icon">
                                <use xlinkHref="#svg-email-icon-1"/>
                            </svg>
                            <div>
                                <span className="title-contact">Email</span>
                                <span className="adds-contact">Roman@egb.com</span>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className="landing-promo-footer">
                <div className="landing-promo-footer-inner">
                    <div className="landing-promo-list-currencies">
                        <span className="item">USD</span>
                        <span className="item">EUR</span>
                        <span className="item">UAH</span>
                        <span className="item">BYN</span>
                        <span className="item">KZT</span>
                    </div>
                    <FooterPartnerList/>
                    <div className="landing-promo-footer-rules">
                        <div className="row">
                            <NavLink
                                className="landing-promo-rules"
                                to={'/public/ru_terms_and_conditions'}
                            >
                                {I18n.t('advertising.terms_conditions')}
                            </NavLink>
                        </div>
                        <div className="row">
                            <NavLink
                                className="landing-promo-rules"
                                to={'/public/privacy_policy'}
                            >
                                {I18n.t('privacy_policy')}
                            </NavLink>
                        </div>
                        <div className="row">
                            <Link
                                className="logo-landing-promo"
                                activeClass="active"
                                to="navAffiliates"
                                smooth={true}
                                offset={-79}
                                duration={400}
                            >
                                <img src={SvgLogoEgbAffiliates} alt="EGB.com" title="EGB.COM"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </>;
};

export default AffiliatesLanding;
