import React from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import {getLinkPath, betMoney, closeMenuAndScrollToTop} from 'app/utils';
import {restorePlayMoney} from './menu';
import UserBalancePoints from 'site_version/com/userBalancePoints';
import ClickableTooltip from 'app/components/reusable/ClickableTooltip';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import FrozenBalance from 'app/interfaces/FrozenBalanceInterface';
import UserBalanceInterface from 'app/interfaces/UserBalanceInterface';
import {fixed2} from 'app/utils/fixed';


interface UserBalanceState {
    frozen_balances: FrozenBalance[];
}

export default class UserMenuBalance extends React.Component<UserBalanceInterface, UserBalanceState> {
    constructor(props: UserBalanceInterface) {
        super(props);
        this.state = {
            frozen_balances: props.frozen_balances
        };
    }

    componentDidUpdate(prevProps: UserBalanceInterface):void {
        if (prevProps.frozen_balances !== this.props.frozen_balances) {
            this.setState({frozen_balances: this.props.frozen_balances});
        }
    }

    bonusName = (): string => {
        const {bonuses} = this.props;

        if (!bonuses) {
            return '';
        }

        const [bonus] = bonuses;

        if (bonus) {
            switch (bonus.type) {
            case 'Bets_Advance':
                return I18n.t('bonus_types.bets_advance');
            case 'Casino_Advance':
                return I18n.t('bonus_types.casino_advance');
            default:
                return '';
            }
        }

        return '';
    };

    handleTimerComplete = (): void => {
        requestAnimationFrame(() => {
            this.setState({frozen_balances: this.state.frozen_balances.slice(1)});
        });
    };

    calculateTotalFrozenAmount = (
        frozen_balances: Array<FrozenBalance>
    ): number => frozen_balances.reduce((sum: number, balance: FrozenBalance) => sum + Number(balance.amount), 0);

    getTooltipContent = (frozenBalance: FrozenBalance): React.ReactNode => <>
        {I18n.t('balance_expiry', {string: fixed2(Number(frozenBalance.amount))})}
        <TimeToStartTimer
            time_to_start={frozenBalance.time}
            textClass="bonus-balance-status-timer"
            afterTimerComplete={this.handleTimerComplete}
        />
    </>;

    renderFrozenBalance = (
        frozenBalance: FrozenBalance,
        frozen_balances: Array<FrozenBalance>
    ): React.ReactNode => <div className="bonus-balance-status">
        <div className="bonus-balance-status-label balance__label">
            {I18n.t('frozen_balance')}:
        </div>
        <div
            className="bonus-balance-status-value bonus_balance__value">
            <div className="bonus-balance-status-icon">
                <ClickableTooltip svg={<use xlinkHref="#svg-timer-icon"/>} text={this.getTooltipContent(frozenBalance)} />
            </div>
            {betMoney({amount: this.calculateTotalFrozenAmount(frozen_balances)})}
        </div>
    </div>;

    render(): React.ReactNode {
        const {new_year_theme} = window.settings.config;
        const bonus_name = this.bonusName();
        const {frozen_balances} = this.state;
        const {bonus_balance, active_bets_sum} = this.props;
        const hasFrozenBalance = frozen_balances.length > 0;
        const frozenBalance = hasFrozenBalance ? frozen_balances[0] : null;
        const showFrozenBalance = frozenBalance && frozenBalance.amount > 0;
        const showBonusBalance = Number(bonus_balance) > 0;
        const showBalanceInGame = Number(active_bets_sum) > 0;

        return <div className="balance">
            <div className="balance__heading">
                <div className="balance-user">
                    <span className="balance-user__icon">
                        <svg height="45px" width="45px">
                            <use xlinkHref={`#svg-account${new_year_theme ? '-santa' : ''}`}/>
                        </svg>
                    </span>
                    <span className="balance-user__name">{this.props.name}</span>
                </div>
                <div className="balance-status">
                    <div className="balance-status__item">
                        <div className="balance-status__item-label balance__label">{I18n.t('user_balance')}:</div>
                        <div
                            className="balance-status__item-value balance__value">
                            {betMoney({amount: this.props.balance})}
                        </div>
                    </div>
                    <UserBalancePoints points= {betMoney({}, this.props.points, true, true)} />
                    <div className="balance-status__item">
                        <div className="balance-status__item-label balance-playmoney__label">
                            {I18n.t('user_playmoney')}:
                        </div>
                        <div className="balance-status__item-value balance-playmoney__value">
                            {betMoney({}, this.props.play_money, false, true)}
                            <span className="balance-status__action">
                                <a
                                    href="#"
                                    onClick={restorePlayMoney}
                                    title={I18n.t('restore_play_money')}
                                >
                                    <svg height="17px" width="17px" id="prevent-close">
                                        <use xlinkHref="#svg-refresh" id="prevent-close"/>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                {showFrozenBalance && this.renderFrozenBalance(frozenBalance, frozen_balances)}
                {showBonusBalance && <div className="bonus-balance-status">
                    <div className="bonus-balance-status-label balance__label">
                        {I18n.t('user_bonus_balance')}{bonus_name ? ` (${bonus_name})` : ''}:
                    </div>
                    <div
                        className="bonus-balance-status-value bonus_balance__value">
                        {betMoney({amount: bonus_balance})}
                    </div>
                </div>}
                {showBalanceInGame && <div className="bonus-balance-status">
                    <div className="bonus-balance-status-label balance__label">
                        {I18n.t('sum_of_active_bets')}:
                    </div>
                    <div
                        className="bonus-balance-status-value bonus_balance__value">
                        {betMoney({amount: active_bets_sum})}
                    </div>
                </div>}
                <div className="balance__btns">
                    <Link
                        to={getLinkPath('/deposits')}
                        onClick={closeMenuAndScrollToTop}
                        className="btn"
                        dangerouslySetInnerHTML={{__html: I18n.t('user.deposit')}}
                    />
                    <Link
                        to={getLinkPath('/cashouts')}
                        onClick={closeMenuAndScrollToTop}
                        className="btn"
                        dangerouslySetInnerHTML={{__html: I18n.t('user.cashout')}}
                    />
                </div>
            </div>
        </div>;
    }
}
