import React from 'react';
import I18n from 'app/i18n';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';
import {Pagination} from 'swiper/modules';
import {NavLink} from 'react-router-dom';

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    speed: 400,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

const AffiliatesLandingTopSlider: React.FC = () => <div className="landing-promo-sections">
    <Swiper className="slider-container-swiper" {...swiperParams}
        slidesPerView="auto"
        freeMode
        preventInteractionOnTransition
        preventClicksPropagation
        resistance={false}
        watchSlidesProgress
        modules={[Pagination]}
        pagination={{
            clickable: true,
            el: '.pagination-landing-sections'
        }}>
        <SwiperSlide>
            <div className="landing-promo-section">
                <svg className="svg-default section-icon">
                    <use xlinkHref="#svg-affiliates-icon-1"/>
                </svg>
                <div>
                    <span className="section-title">{I18n.t('affiliate.banner_text_1')}</span>
                    <span className="section-title">{I18n.t('affiliate.banner_text_1_1')}</span>
                </div>
            </div>
            <div className="landing-promo-section">
                <svg className="svg-default section-icon">
                    <use xlinkHref="#svg-affiliates-icon-2"/>
                </svg>
                <span className="section-title">{I18n.t('affiliate.banner_text_2')}</span>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="landing-promo-section">
                <svg className="svg-default section-icon">
                    <use xlinkHref="#svg-affiliates-icon-3"/>
                </svg>
                <span className="section-title">{I18n.t('affiliate.banner_text_3')}</span>
            </div>
            <div className="landing-promo-section">
                <svg className="svg-default section-icon">
                    <use xlinkHref="#svg-affiliates-icon-4"/>
                </svg>
                <span className="section-title">{I18n.t('affiliate.banner_text_4')}</span>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="landing-promo-section">
                <svg className="svg-default section-icon">
                    <use xlinkHref="#svg-affiliates-icon-5"/>
                </svg>
                <span className="section-title">{I18n.t('affiliate.banner_text_5')}</span>
            </div>
        </SwiperSlide>
    </Swiper>
    <div className="swiper-pagination-group pagination-landing-sections"/>
    <NavLink
        className="button-become-partner"
        to="https://my.egbaffiliates.com/registration"
        rel="noreferrer noopener"
    >{I18n.t('affiliate.become_partner')}</NavLink>
    <div className="landing-promo-section banner">
        <span className="section-subtitle">{I18n.t('affiliate.banner_text_6')}</span>
        <span className="section-big-title">{I18n.t('affiliate.banner_text_6_1')}</span>
        <span className="section-title">{I18n.t('affiliate.banner_text_6_2')}</span>
    </div>
</div>;

export default AffiliatesLandingTopSlider;
