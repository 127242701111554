import React from 'react';
import I18n from 'app/i18n';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';
import {Pagination} from 'swiper/modules';

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    speed: 400,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

const AffiliatesLandingProductSlider: React.FC = () => <div className="landing-promo-product-list">
    <Swiper className="slider-container-swiper" {...swiperParams}
        slidesPerView="auto"
        freeMode
        preventInteractionOnTransition
        preventClicksPropagation
        resistance={false}
        watchSlidesProgress
        modules={[Pagination]}
        pagination={{
            clickable: true,
            el: '.pagination-landing-products'
        }}>
        <SwiperSlide>
            <div className="landing-promo-product-list-item">
                <svg className="svg-default product-icon">
                    <use xlinkHref="#svg-tap_bar_play"/>
                </svg>
                <span className="title-product">{I18n.t('menu_links.bets')}</span>
                <span className="description-product">{I18n.t('affiliate.products_text_1')}</span>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="landing-promo-product-list-item">
                <svg className="svg-default product-icon">
                    <use xlinkHref="#svg-tap_bar_play_sport"/>
                </svg>
                <span className="title-product">{I18n.t('menu_links.sport')}</span>
                <span className="description-product">{I18n.t('affiliate.products_text_2')}</span>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="landing-promo-product-list-item">
                <svg className="svg-default product-icon">
                    <use xlinkHref="#svg-tap_bar_play_casino"/>
                </svg>
                <span className="title-product">{I18n.t('menu_links.casino')}</span>
                <span className="description-product">{I18n.t('affiliate.products_text_3')}</span>
            </div>
        </SwiperSlide>
    </Swiper>
    <div className="swiper-pagination-group pagination-landing-products"/>
</div>;

export default AffiliatesLandingProductSlider;
